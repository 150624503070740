<template>
  <div>
    <breadcrumbs
      :breadcrumb-items="breadcrumbItems"
    />
    <UserToolbar
      v-if="authUserData.user_id !== $route.params.userId"
      :account="user"
    />
    <b-card>
      <CampaignToolbar
        v-if="Object.keys(campaign).length !== 0"
        :campaign="campaign"
        :selected-banners="computedSelected"
        closing
        @uploadBanners="uploadBannersImg"
        @deleteBanner="deleteBannersImg"
        @toggleStatusBanners="toggleStatusBannersImg"
      />
      <b-progress
        v-if="progress"
        animated
        :max="100"
        :variant="progressVariant"
        :class="`mt-1 progress-bar-${progressVariant}`"
      >
        <b-progress-bar
          :value="progress"
          :variant="progressVariant"
          striped
        >
          {{ progressStep }} / {{ progressTotal }}
        </b-progress-bar>
      </b-progress>
    </b-card>
    <template v-if="campaign.type !== 'criteo' && campaign.type !== 'cpcfeed' && campaign.type !== 'adsense'">
      <div v-if="Object.keys(banners).length !== 0">
        <b-row>
          <b-col
            v-for="banner in banners"
            :key="banner._id"
            xl="3"
            lg="4"
            md="6"
          >
            <b-card
              no-body
              class="banner-item"
            >
              <b-card-header class="flex-nowrap pr-0">
                <h5 class="mb-0">
                  {{ banner.title || '' }}
                </h5>
                <div>
                  <b-dropdown
                    variant="link"
                    toggle-class="text-decoration-none"
                    no-caret
                    right
                    size="sm"
                  >
                    <template v-slot:button-content>
                      <feather-icon
                        icon="MoreVerticalIcon"
                        size="16"
                        class="text-body align-middle mr-25"
                      />
                    </template>
                    <b-dropdown-item
                      :to="'/users/'+ $route.params.userId + '/campaigns/'+ $route.params.id + '/creatives/' + banner._id + '/edit'"
                    >
                      <feather-icon
                        icon="Edit2Icon"
                        class="mr-50"
                      />
                      <span>{{ $t('settings') }}</span>
                    </b-dropdown-item>
                    <b-dropdown-item
                      @click="copyBanner(banner._id)"
                    >
                      <feather-icon
                        icon="CopyIcon"
                        class="mr-50"
                      />
                      <span>{{ $t('form.copy') }}</span>
                    </b-dropdown-item>
                    <b-dropdown-item
                      :to="'/users/'+ $route.params.userId + '/campaigns/'+ $route.params.id + '/creatives/' + banner._id"
                    >
                      <feather-icon
                        icon="BarChart2Icon"
                        class="mr-50"
                      />
                      <span>{{ $t('Statistics') }}</span>
                    </b-dropdown-item>
                    <b-dropdown-item
                      variant="danger"
                      @click="deleteBannerImg(banner._id)"
                    >
                      <feather-icon
                        icon="Trash2Icon"
                        class="mr-50"
                      />
                      <span>{{ $t('form.delete') }}</span>
                    </b-dropdown-item>
                  </b-dropdown>
                </div>
              </b-card-header>
              <div class="banner-img">
                <button
                  class="btn-status btn btn-sm"
                  :class="{
                    'btn-relief-success': banner.status === 'active',
                    'btn-relief-danger': !banner.is_moderate && banner.status === 'inactive',
                    'btn-warning is_moderate': banner.is_moderate,
                  }"
                  @click="toggleStatusBannerImg(banner)"
                >
                  {{ banner.is_moderate ? $t('campaigns.moderated') : $t(`selects.statuses.${banner.status}`) }}
                </button>
                <label>
                  <b-badge
                    variant="primary"
                    class="badge-glow"
                    pill
                  >
                    {{ new Intl.NumberFormat('en-GB', { style: 'decimal', maximumSignificantDigits: 2 }).format(banner.total_ctr) }}%
                  </b-badge>
                  <b-form-checkbox
                    v-model="selectedBanners"
                    :value="banner._id"
                  />
                  <b-img
                    :src="`${$creativeImgUrl}/${banner.image_id}.jpg`"
                    fluid
                    :alt="banner.title"
                  />
                </label>
              </div>
            </b-card>
          </b-col>
        </b-row>
      </div>
    </template>
  </div>
</template>
<script>
import { getUserData } from '@/auth/utils'
import useJwt from '@/auth/jwt/useJwt'
import {
  BCard,
  BCardHeader,
  BImg,
  BRow,
  BCol,
  BDropdown,
  BDropdownItem,
  BBadge,
  BFormCheckbox,
  VBTooltip,
  BProgress,
  BProgressBar,
} from 'bootstrap-vue'
import Breadcrumbs from '@/views/ui/breadcrumbs/Breadcrumbs.vue'
import UserToolbar from '@/views/dashboard/users/UserToolbar.vue'
import CampaignToolbar from '@/views/dashboard/campaigns/CampaignToolbar.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'
import wait from '@/utils/wait'

export default {
  components: {
    Breadcrumbs,
    UserToolbar,
    BCard,
    BCardHeader,
    BImg,
    BRow,
    BCol,
    BDropdown,
    BDropdownItem,
    BBadge,
    BFormCheckbox,
    CampaignToolbar,
    BProgress,
    BProgressBar,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data() {
    return {
      pageTitle: '',
      pageSubtitle: '',
      breadcrumbItems: [],
      user: {},
      campaign: {},
      banners: [],
      selectedBanners: [],
      authUserData: getUserData(),
      progress: 0,
      progressStep: 0,
      progressTotal: 0,
      progressVariant: 'success',
      newBanners: [],
    }
  },
  computed: {
    computedSelected() {
      return this.selectedBanners.length
    },
  },
  created() {
    this.getUser(this.$route.params.userId)
  },
  methods: {
    async getBanners() {
      const dataResponse = await useJwt.getCampaignBanners({ campaign_id: this.$route.params.id })
      this.banners = dataResponse.data.banners || []
      this.setBreabcrumbs()
    },
    async getCampaignData() {
      const responseData = await useJwt.getCampaign(this.$route.params.id)
      this.campaign = responseData.data.campaign || {}
      this.getBanners()
    },
    async getUser(id) {
      const responseData = await useJwt.getUser(id)
      this.user = responseData.data.account || {}
      this.pageTitle = this.user.name
      this.pageSubtitle = this.user.email
      this.getCampaignData()
    },
    uploadBannersImg(files) {
      const formData = new FormData()
      const fileList = files
      fileList.map(f => formData.append('files', f, f.name))
      useJwt.uploadBannersImg(this.$route.params.id, formData)
        .then(wait(1500))
        .then(response => {
          const { banners } = response.data
          if (banners.length === 1) {
            this.$router.push(`/users/${this.$route.params.userId}/campaigns/${this.$route.params.id}/creatives/${banners[0]}/create`)
          } else {
            this.getBanners()
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Success',
                icon: 'EditIcon',
                variant: 'success',
              },
            })
          }
          document.querySelector('.form-control-file').value = ''
        })
    },
    async copyBanner(id) {
      await useJwt.copyBannerImg(id)
      this.getBanners()
    },
    deleteBannerImg(id) {
      this.$swal({
        title: 'Want to delete creative?',
        text: 'After that creative could not be restored',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Delete',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          useJwt.deleteBannerImg(id).then(deleted => {
            if (deleted.status === 204) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: this.$t('swal.successfullydeleted'),
                  icon: 'BellIcon',
                  variant: 'success',
                },
              })
              this.getBanners()
            }
          })
        }
      })
    },
    async deleteBannersImg() {
      await this.$swal({
        title: 'Are you sure you want to delete the selected creatives?',
        text: 'After that creative could not be restored',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Delete',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(async result => {
        if (result.value) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: this.$t('swal.successfullydeleted'),
              icon: 'BellIcon',
              variant: 'success',
            },
          })

          const totalBanners = this.selectedBanners.length
          this.progressTotal = totalBanners
          const stepWidth = 100 / totalBanners
          this.progressVariant = 'danger'
          this.selectedBanners.forEach(c => {
            useJwt.deleteBannerImg(c).then(response => {
              if (response.status === 204) {
                this.progressStep += 1
                this.progress += stepWidth
              }
            })
          })
          setTimeout(() => {
            this.progress = 0
            this.progressStep = 0
            this.progressTotal = 0
            this.progressVariant = 'success'
            this.selectedBanners = []
            this.getBanners()
          }, 1000)
        }
      })
    },
    async toggleStatusBannerImg(banner) {
      if (!banner.is_moderate) {
        if (banner.status === 'inactive') {
          banner.status = 'active'
          // new Date(date).toISOString()
          if (banner.targetings.date.params.startdate !== '') {
            banner.targetings.date.params.startdate = new Date(banner.targetings.date.params.startdate).toISOString()
          }

          if (banner.targetings.date.params.enddate !== '') {
            banner.targetings.date.params.enddate = new Date(banner.targetings.date.params.enddate).toISOString()
          }

          await useJwt.setBanner(banner._id, banner)
            .then(() => {
              this.$swal({
                icon: 'success',
                title: 'Activation!',
                text: 'Successfully activated.',
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
            })
            .catch(error => {
              banner.status = 'inactive'
              if (error.response && error.response.status === 422) {
                if (error.response.data.errors.length) {
                  error.response.data.errors.forEach(err => {
                    const field = err.source.pointer.split('/')
                    this.$store.dispatch(
                      'error-store/showNotify',
                      {
                        title: `Error [${field[field.length - 1]}]`,
                        msg: err.detail,
                        variant: 'danger',
                      },
                      { root: true },
                    )
                  })
                }
              }
              if (error.response && error.response.status === 500) {
                this.$store.dispatch(
                  'error-store/showNotify',
                  {
                    title: error.response.statusText,
                    msg: error.message,
                    variant: 'danger',
                  },
                  { root: true },
                )
              }
            })
        } else {
          this.$swal({
            title: 'Confirmation',
            text: 'Will be inactive',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Ok',
            customClass: {
              confirmButton: 'btn btn-primary',
              cancelButton: 'btn btn-outline-danger ml-1',
            },
            buttonsStyling: false,
          }).then(async result => {
            if (result.value) {
              banner.status = 'inactive'
              // new Date(date).toISOString()
              if (banner.targetings.date.params.startdate !== '') {
                banner.targetings.date.params.startdate = new Date(banner.targetings.date.params.startdate).toISOString()
              }
              if (banner.targetings.date.params.enddate !== '') {
                banner.targetings.date.params.enddate = new Date(banner.targetings.date.params.enddate).toISOString()
              }
              await useJwt.setBanner(banner._id, banner)
                .then(() => {
                  this.$swal({
                    icon: 'success',
                    title: 'Deactivation!',
                    text: 'Successfully deactivated',
                    customClass: {
                      confirmButton: 'btn btn-success',
                    },
                  })
                })
                .catch(error => {
                  if (error.response && error.response.status === 422) {
                    if (error.response.data.errors.length) {
                      error.response.data.errors.forEach(err => {
                        const field = err.source.pointer.split('/')
                        this.$store.dispatch(
                          'error-store/showNotify',
                          {
                            title: `Error [${field[field.length - 1]}]`,
                            msg: err.detail,
                            variant: 'danger',
                          },
                          { root: true },
                        )
                      })
                    }
                  }
                  if (error.response && error.response.status === 500) {
                    this.$store.dispatch(
                      'error-store/showNotify',
                      {
                        title: error.response.statusText,
                        msg: error.message,
                        variant: 'danger',
                      },
                      { root: true },
                    )
                  }
                })
            } else if (result.dismiss === 'cancel') {
              banner.status = 'active'
              this.$swal({
                title: 'Cancelled',
                text: 'Status remain active',
                icon: 'error',
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
            }
          })
        }
      }
    },
    toggleStatusBannersImg(status) {
      const totalBanners = this.selectedBanners.length
      this.progressTotal = totalBanners
      const stepWidth = 100 / totalBanners
      this.progressVariant = status === 'inactive' ? 'secondary' : 'success'
      this.selectedBanners.forEach(sb => {
        const bannerData = this.banners.find(b => b._id === sb)
        const currentBanner = { ...bannerData }

        if (currentBanner.targetings.date.params.startdate !== '') {
          currentBanner.targetings.date.params.startdate = new Date(currentBanner.targetings.date.params.startdate).toISOString()
        }

        if (currentBanner.targetings.date.params.enddate !== '') {
          currentBanner.targetings.date.params.enddate = new Date(currentBanner.targetings.date.params.enddate).toISOString()
        }

        if (!currentBanner.is_moderate) {
          currentBanner.status = status
          useJwt.setBanner(sb, currentBanner).then(response => {
            if (response.status === 204) {
              this.progressStep += 1
              this.progress += stepWidth
            }
          })
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `${this.$t('notifications.creative.name')} "${currentBanner.title}" ${this.$t('campaigns.moderated').toLowerCase()}`,
              icon: 'EditIcon',
              variant: 'warning',
            },
          })
        }
      })
      setTimeout(() => {
        this.progressVariant = 'success'
        this.progress = 0
        this.progressStep = 0
        this.progressTotal = 0
        this.selectedBanners = []
        this.getBanners()
      }, 1000)
    },
    setBreabcrumbs() {
      this.breadcrumbItems = [
        {
          text: 'users',
          to: '/users',
        },
        {
          text: this.user.name,
          to: `/users/${this.$route.params.userId}`,
        },
        {
          text: 'campaigns',
          to: `/users/${this.$route.params.userId}/campaigns`,
        },
        {
          text: this.campaign.title,
          active: true,
        },
      ]
    },
  },
}
</script>
<style scoped>
.card .card-header {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.banner-item .card-header h5 {
  font-size: 12px;
}

.feather-trash-2 {
  margin-top: -3px;
}
.banner-img {
  position: relative;
}

.banner-img .badge {
  position: absolute;
  left: 15px;
  top: 15px;
}

.banner-img .btn-status {
  position: absolute;
  bottom: 15px;
  left: 15px;
}

.custom-control {
  position: absolute;
  right: 10px;
  top: 15px;
}

.banner-img label {
  margin: 0;
  padding: 0;
  display: block;
}

.banner-img .img-fluid {
  width: 100%;
}

.btn.is_moderate:hover {
  cursor: default;
  box-shadow: none;
  color: #fff;
  border-color: #ff9f43 !important;
  background-color: #ff9f43 !important;
}
</style>
